<template>
  <div class="container">
    <div class="goods-select-bar">
      <div class="goods-select-item "
           :class="{active:rankType == 0}"
           @click="sorttype(0)">
        综合
        <span class="split-line">/</span>
      </div>
      <div class="goods-select-item"
           :class="{active:rankType == 2}"
           @click="sorttype(2)">
        销量
        <span class="split-line">/</span>
      </div>
      <div class="goods-select-item"
           :class="{active:rankType == 3 || rankType == 4 }"
           @click="sorttype(3)">
        价格
        <span class="split-line">/</span>
      </div>
      <div class="goods-select-item"
           @mouseleave="handleAreaMaskReset()"
           @click="isOpenAreaMask = true">
        {{ countyName || "产地" }}
        <span class="split-line">/</span>
        <div class="del iconfont icon-shanchu"
             v-show="countyName"
             @click.stop="handleDelArea()"></div>
        <div class="area-list"
             v-if="areaList.length && isOpenAreaMask">
          <div class="area-container"
               v-for="(item, index) in areaList"
               :key="index"
               @click.stop="
                lv1Idx = index;
                showListFlag = 1;
              "
               v-show="showListFlag === 0">
            {{ item.areaName }}
          </div>
          <div class="area-container"
               v-for="(item, index) in areaList[lv1Idx].list"
               :key="index"
               @click.stop="
                lv2Idx = index;
                showListFlag = 2;
              "
               v-show="showListFlag === 1">
            {{ item.areaName }}
          </div>
          <div class="area-container"
               v-for="(item, index) in areaList[lv1Idx].list[
                lv2Idx
              ].list"
               :key="index"
               v-show="showListFlag === 2"
               @click.stop="submitArea(item)">
            {{ item.areaName }}
          </div>
        </div>
      </div>

      <div class="search-tips">
        共找到 <span class="keyword">{{totalCount}}</span> 款符合条件的商品
      </div>
    </div>
    <!-- 商品列表 -->
    <ul class="goods-list"
        v-if="resultList.length">
      <li class="li-goods"
          v-for="item in resultList"
          :key="item.id"
          @click="handleToGoods(item)">
        <img v-lazy="item.pictureList">
        <p class="name ellipsis-2">{{ item.title }}</p>
        <p class="price-bar"><span class="discount">&yen; {{item.price}}</span> <span class="org-price">&yen; {{item.yptMarketPrice}}</span> </p>
      </li>
    </ul>
    <div class="slok"
         v-else>
      <qdzEmpty state="notResult" />
    </div>
    <qdzPagination v-if="resultList.length && refresh"
                   :total="totalCount"
                   :pageSize="pageSize"
                   :currentPage="pageNo"
                   @handleCheckPage="handleCheckPage" />
  </div>
</template>
<script >
import { toRefs, reactive, watch, } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { searchGoodsNew, APIGetArea } from '@/api/search'
export default {
  setup () {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      keyword: '',
      pageNo: 1,
      totalCount: 1,
      totalPage: 1,
      pageSize: 10,
      resultList: [],
      refresh: true,
      rankType: 0,
      countyName: '',

      // 产地
      areaList: [],
      isOpenAreaMask: false,
      showListFlag: 0,
      lv1Idx: 0,
      lv2Idx: 0,
    })

    const handleGetResultList = (data) => {
      searchGoodsNew(data).then(res => {
        if (res.code === '10000') {
          state.resultList = res.data.list
          state.pageNo = res.data.pageNo
          state.totalCount = res.data.totalCount
          state.refresh = true
        }
      })
    }

    // 重置区域
    const handleDelArea = () => {
      state.countyName = '';
      state.pageNo = 1
      state.resultList = [];
      state.rankType = 0
      handleGetResultList({
        keyword: route.query.keyword,
        showClass_id: route.query.id,
        type: 1,
        rankType: state.rankType,
        countyName: state.countyName,
        pageNo: state.pageNo
      })
    }
    // 提交区域
    function submitArea (item) {
      handleAreaMaskReset();
      state.countyName = item.areaName;
      // pageNo = 1;
      state.pageNo = 1
      state.resultList = [];
      state.rankType = 0
      handleGetResultList({
        keyword: route.query.keyword,
        showClass_id: route.query.id,
        type: 1,
        rankType: state.rankType,
        countyName: state.countyName,
        pageNo: state.pageNo
      })

    }
    // 获取区域列表
    function getAreaList () {
      APIGetArea().then((res) => {
        // console.log(res);
        state.areaList = res.data;
      });
    }

    // 重置区域列表选择器操作
    function handleAreaMaskReset () {
      state.showListFlag = 0;
      state.lv1Idx = 0;
      state.lv2Idx = 0;
      state.isOpenAreaMask = false;
    }



    watch(() => route.query, (value, oldVal) => {
      state.keyword = value.keyword
      // 关键词不同时 重新 初始化分页器 优化
      if (value.keyword != oldVal?.keyword) {
        state.refresh = false
      }
      handleGetResultList({
        keyword: value.keyword,
        showClass_id: value.id
      })
      getAreaList()
    }, { immediate: true })
    //  筛选数据  
    const sorttype = (type) => {
      if (type == 3) {
        type = state.rankType == 4 ? 3 : 4
      };

      if (state.rankType == type) return;
      state.refresh = false
      state.rankType = type;
      state.pageNo = 1;
      handleGetResultList({
        keyword: route.query.keyword,
        showClass_id: route.query.id,
        type: 1,
        rankType: state.rankType,
        countyName: state.countyName,
        pageNo: state.pageNo
      })
    }
    // 切换分页
    const handleCheckPage = (pageNo) => {
      state.pageNo = pageNo
      handleGetResultList({
        keyword: route.query.keyword,
        showClass_id: route.query.id,
        type: 1,
        rankType: state.rankType,
        countyName: state.countyName,
        pageNo: pageNo
      })
    }
    // 去详情页
    const handleToGoods = (item) => {
      // 条件判断跳转 文章详情 还是、商品详情
      // console.log(item)
      if (item.luceneType === 'goods') {
        let toPath = router.resolve({
          path: 'goods',
          query: {
            goodsId: item.id
          }
        })
        window.open(toPath.href, '_blank')
      }
    }
    return {
      ...toRefs(state),
      sorttype,
      handleCheckPage,
      handleToGoods,
      handleAreaMaskReset,
      submitArea,
      handleDelArea
    }
  }
}

</script>
<style scoped lang="less">
.goods-select-bar {
  display: flex;
  align-items: center;
  margin: 30px 0;
  .goods-select-item {
    color: #bec3c9;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    &.active {
      color: #111;
    }
    .jiantou {
      display: inline-block;
    }
    .arr,
    .up,
    .down {
      display: block;
      font-size: 40px;
    }
  }
  .split-line {
    margin: 0 19px;
    color: #bec3c9;
  }
}
.search-tips {
  font-size: 12px;
  font-weight: 400;
  color: #9499a3;
}
.keyword {
  color: #d6403b;
}
.slok {
  height: 1000px;
}
.goods-list {
  display: flex;
  flex-wrap: wrap;
  height: 686px;
  li {
    .li-goods();
  }
}

// 区域
.del {
  color: #d43834;
  position: absolute;
  right: 20px;
  top: -14px;
}
.area-list {
  width: 270px;
  height: 300px;
  position: absolute;
  left: 0;
  top: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
  box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  padding: 10px;
  overflow-y: scroll;

  .area-container {
    width: fit-content;
    height: 24px;
    padding: 0 10px;
    flex-shrink: 0;
    display: inline-block;
    margin-right: 10px;
    box-sizing: border-box;
    line-height: 24px;
    background-color: #eee;
    margin-bottom: 10px;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
  }
}
</style>
