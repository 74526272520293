// 定义搜索页面需要的接口函数
import request from '@/utils/request'
/**
 * 搜索页面 - 列表
 * @returns Promise
 */
export const searchGoodsNew = (data) => {
  return request('/honghu-search-service/search/searchGoodsNew', 'get', data)
}
export function APIGetArea (data) {
  return request('/honghu-common-service/area/getArea', 'get', data)
}
export function APIGetList (data) {
  return request('/honghu-app-service/theme/goods/list', 'get', data)
}